.depositPopupQR, .depositPopupQRActive {
  position: fixed;
  top: 0;
  left: 300%;
  width: 100%;
  height: 100%;
  z-index: 5;
  max-width: 640px;
  margin: 0 auto;
  padding: 16px 16px 0;
  transition: all 0.2s;
  overflow: auto;

  &__name {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    margin-top: 10px;
  }

  &__nameToken {
    display: flex;
    column-gap: 4px;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }

    span {
      font-size: 24px;
      padding-top: 2px;
    }
  }

  &__network {
    font-size: 13px;
    padding: 7px;
    border-radius: 7px;
    color: #fff;
    font-weight: 500;
  }
}

.depositPopupQRActive {
  left: 50%;
  transform: translateX(-50%);
}

.codeQR {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  margin: 16px auto;

  img {
    background-color: #9BAFD1;
    width: 200px;
    height: 200px;
    border-radius: 8px;
  }
}

.info {
  padding: 16px 20px;
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
  border-radius: 18px;

  p {
    color: #FE8442;
    font-size: 15px;
  }
}

.copyName {
  margin-top: 14px;
  padding: 18px;
  border-radius: 18px;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }

  &__label {
    font-size: 15px;
    opacity: 0.5;
  }

  &__name {
    font-size: 14px;
    font-weight: 600;
  }
}

.button {
  width: 52px;
  min-width: 52px;
  border-radius: 8px;
}

.actionWrapper {
  height: 105px;
  max-width: 640px;
  width: 100%;
  margin-top: 30px;
}

.action {
  height: 65px;
  border-radius: 18px;
  border: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
}