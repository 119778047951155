.walletList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  overflow-x: hidden;


  &__item {
    padding: 18px 18px 8px;
    border-radius: 18px;
    background-color: #0D182E;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.currency {
  display: flex;
  column-gap: 16px;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    background-color: transparent;
  }

  h2 {
    font-size: 18px;
  }
}

.balances {
  display: flex;
  flex-direction: column;
  row-gap: 15px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
  }
}