*{padding: 0;margin: 0;border: 0;}
*,*:before, *:after{-moz-box-sizing: border-box;-webkit-box-sizing: border-box;box-sizing: border-box;}
:focus, :active{outline: none;}
a:focus,a:active{outline: none;}
nav, footer, header, aside{display: block;}
html, body{height: 100%;width: 100%;font-size: 100%;-ms-text-size-adjust: 100%;-moz-text-size-adjust: 100%;-webkit-text-size-adjust: 100%;}
input, button, textarea{font-family: inherit;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner{padding: 0;border: 0;}
a, a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}
h1,h2,h3,h4,h5,h6 {font-size: inherit;font-weight: 400 ;}
textarea{resize: none;}
a, button {-webkit-tap-highlight-color: transparent;}
button {
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

html {
  height: 100vh;
}

body {
  font-family: 'Roboto';
  overflow: hidden;
}

body > div {
  height: 100%;
}

input{
  border-radius: 14px;
  color: #fff;
  background-color: #0D182E;
  font-size: 15px;
  padding-left: 45px;
}

.contentWrapper {
  height: 100%;
  max-width: 640px;
  margin: 0 auto;
  //padding-bottom: 102px;

  p, span, h2, label {
    line-height: 0.9em;
    color: #fff;
    letter-spacing: -0.03em;
  }
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Light'), local('BaiJamjuree-Light'),
  url('./assets/fonts/Baijamjureelight.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureelight.woff') format('woff'),
  url('./assets/fonts/Baijamjureelight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Regular'), local('BaiJamjuree-Regular'),
  url('./assets/fonts/Baijamjureeregular.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureeregular.woff') format('woff'),
  url('./assets/fonts/Baijamjureeregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree SemiBold'), local('BaiJamjuree-SemiBold'),
  url('./assets/fonts/Baijamjureesemibold.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureesemibold.woff') format('woff'),
  url('./assets/fonts/Baijamjureesemibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Italic'), local('BaiJamjuree-Italic'),
  url('./assets/fonts/Baijamjureeitalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureeitalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureeitalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Medium Italic'), local('BaiJamjuree-MediumItalic'),
  url('./assets/fonts/Baijamjureemediumitalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureemediumitalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureemediumitalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree ExtraLight Italic'), local('BaiJamjuree-ExtraLightItalic'),
  url('./assets/fonts/Baijamjureeextralightitalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureeextralightitalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureeextralightitalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Light Italic'), local('BaiJamjuree-LightItalic'),
  url('./assets/fonts/Baijamjureelightitalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureelightitalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureelightitalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Medium'), local('BaiJamjuree-Medium'),
  url('./assets/fonts/Baijamjureemedium.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureemedium.woff') format('woff'),
  url('./assets/fonts/Baijamjureemedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree ExtraLight'), local('BaiJamjuree-ExtraLight'),
  url('./assets/fonts/Baijamjureeextralight.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureeextralight.woff') format('woff'),
  url('./assets/fonts/Baijamjureeextralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Bold Italic'), local('BaiJamjuree-BoldItalic'),
  url('./assets/fonts/Baijamjureebolditalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureebolditalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureebolditalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree SemiBold Italic'), local('BaiJamjuree-SemiBoldItalic'),
  url('./assets/fonts/Baijamjureesemibolditalic.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureesemibolditalic.woff') format('woff'),
  url('./assets/fonts/Baijamjureesemibolditalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Bai Jamjuree';
  src: local('Bai Jamjuree Bold'), local('BaiJamjuree-Bold'),
  url('./assets/fonts/Baijamjureebold.woff2') format('woff2'),
  url('./assets/fonts/Baijamjureebold.woff') format('woff'),
  url('./assets/fonts/Baijamjureebold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

.idTest {
  position: fixed;
  top: 50px;
  left: 35%;
  font-size: 20px;
  color: #fff;
  z-index: 999;
}