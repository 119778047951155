.depositNetwork {
  padding: 14px 18px 9px;
  display: flex;
  flex-direction: column;
  row-gap: 9px;
  border-radius: 18px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    img {
      opacity: 0.5;
    }
  }

  &__headerName {
    display: flex;
    column-gap: 11px;
    align-items: center;
    overflow: hidden;
    max-width: 50%;

    p {
      font-size: 24px;
    }

    span {
      background-color: #16399d;
      border-radius: 7px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
      padding: 6px;
    }
  }

  &__address {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    button {
      width: 44px;
      height: 39px;
      border-radius: 8px;
    }
  }

  &__addressBox {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }

  &__addressValue {
    font-size: 16px;
    opacity: 0.5;
    max-width: 70%;
    overflow: hidden;
    letter-spacing: -0.03em;
  }

  &__addressIndex {
    font-size: 10px;
    opacity: 0.5;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}