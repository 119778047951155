.menu {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  column-gap: 8px;

  button {
    width: 100%;
    display: flex;
    column-gap: 8px;
    justify-content: center;
    align-items: center;
    height: 40px;
    background: #FFFFFF33;
    border-radius: 12px;

    span {
      font-size: 15px;
      color: #fff;
    }
  }
}