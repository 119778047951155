.tokenBalance {
  padding: 25px 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 18px;
  margin: 10px 0 8px;

  &__token {
    display: flex;
    column-gap: 16px;

    img {
      width: 40px;
      height: 40px;
      background-color: transparent;
    }
  }

  &__balance {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }

  &__network {
    span {
      padding: 7px;
      font-size: 13px;
      font-weight: 500;
      border-radius: 7px;
    }
  }
}

.subtitle {
  font-size: 24px;
}

.value {
  font-size: 15px;
  opacity: 0.4;
}