.popupActive, .popup {
  position: fixed;
  left: 50%;
  bottom: -2000px;
  max-width: 640px;
  transform: translateX(-50%);
  width: 100%;
  height: auto;
  transition: all .2s;
  z-index: 3;
  padding: 16px 16px 100px;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 24px auto 32px;
    text-align: center;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px;
    border-radius: 18px;
    margin-bottom: 13px;

    img {
      width: 40px;
      height: 40px;
    }

    p {
      font-size: 18px;
      letter-spacing: -0.03em;
    }

    span {
      font-size: 12px;
      letter-spacing: -0.03em;
      opacity: 0.3;
    }
  }

  &__headerLeft {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
    padding: 18px;
    border-radius: 18px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 15px;
        letter-spacing: -0.03em;
      }
    }
  }
}

.popupActive {
  bottom: 0;
}