.balanceWrapper {
  margin-top: 16px;

  p {
    font-size: 18px;
    margin-bottom: 16px;
    color: #fff;
  }

  h2 {
    font-family: 'Bai Jamjuree';
    font-weight: 700;
    font-size: 42px;
    color: #fff;
  }
}

.balance {
  display: flex;
  column-gap: 6px;
  align-items: center;

  img {
    height: 38px;
    width: 38px;
  }
}

span.currency {
  padding: 5px 9px 3px;
  display: block;
  border-radius: 28px;
  text-transform: uppercase;
  color: #000;
  font-size: 11px;
  font-weight: 700;
  margin-bottom: 3px;
  letter-spacing: -0.03em;
}