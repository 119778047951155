.historyOrdersList {
  margin-top: 37px;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow: auto;
    max-height: calc(100vh - 330px);
    padding-bottom: 8px;
  }
}

.showMore {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;

  button {
    padding: 4px;

    span {
      font-weight: 500;
    }
  }
}