.transactionsList {
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-height: calc(100vh - 315px);
  overflow: auto;
}

.showMore {
  display: flex;
  justify-content: center;
  padding-bottom: 16px;

  button {
    padding: 4px;

    span {
      font-weight: 500;
    }
  }
}