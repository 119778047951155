.main {
  height: 100vh;
  position: relative;
  background-position: 50% 235%;
  background-size: cover;

  &__headerContainer {
    padding: 30px 23px 0;
  }
}


.closer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1;
}