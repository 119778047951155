.deposit {
  height: 100vh;
  padding: 24px 16px;
  position: relative;

  ul {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 185px);
    margin-top: 20px;
  }
}


.action {
  position: fixed;
  bottom: 22px;
  height: 65px;
  border-radius: 18px;
  border: 18px;
  max-width: calc(640px - 32px);
  width: calc(100vw - 32px);
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }
}